.note {
  color: #dc1b1b;
}
@font-face {
  font-family: exophis;
  src: url('./elements/Exophis-Regular.woff');
}

@font-face {
  font-family: south-blues;
  src: url('./elements/southblues.ttf');
}

* {
  text-align: center;
  margin: 0 auto;
  overflow-x: hidden;
}

body {
  width: 100%;
  color: #FEF9E6;
  font-family: 'Lora', serif;
  background-color: #003049;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.home {
  background-color: #003049;
}

a {
  text-decoration: none;
}

.contact_type_detail a:visited {
  color: #FEF9E6;
}

.header {
  background-image: url('./elements/header_banner.png');
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.marquee-container {
  width: 100%;
  background-color: rgb(254, 249, 230, .83);
  padding-top: 10px;
  padding-bottom: 10px;
}

.border-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-top: 3px solid #003049;
  border-bottom: 3px solid #003049;
}

.header_logo {
  width: 20%;
}

.logo_container {
  width: 100%;
  margin: 15px auto 15px auto;
}

.marquee {
  color:#f9f9f9;
  font-size: 58px;
  font-family: south-blues;
  font-weight: 100;
  margin-top: -20px;

  }

.marquee2 {
  font-size: 28px;
  color: #003049;
  font-family: exophis;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 15px;
}

.accent {
  width: 50px;
  height: 50px;
  margin: auto;
}
.nav_bar {
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 15px auto 15px auto;
  padding-top: 20px;
}

.nav_tiles {
  width: 75%;
  border: 2px solid black;
  border-radius: 16px;
  box-shadow: 10px 10px 8px -8px rgba(0,0,0,0.52);
  -webkit-box-shadow: 10px 10px 8px -8px rgba(0,0,0,0.52);
  -moz-box-shadow: 10px 10px 8px -8px rgba(0,0,0,0.52);
}

.nav_tiles:hover {
  filter: brightness(70%);
  width: 72%;
}

.welcome_message_container {
  margin: 30px auto 40px auto;
  width: 45%;
}

.welcome_message_container p {
  font-size: 22px;
  padding: 10px;
  line-height: 175%;
  font-style: oblique;
}

.welcome_message_container img {
  width: 80%;
  padding: 7px;
  border: 2px solid #FEF9E6;
  margin: 25px auto;
  box-shadow: 10px 10px 8px -8px rgba(0,0,0,0.62);
  -webkit-box-shadow: 10px 10px 8px -8px rgba(0,0,0,0.62);
  -moz-box-shadow: 10px 10px 8px -8px rgba(0,0,0,0.62);
}

.feed_container {
  width: 50%;
  /* display: flex;
  flex-direction: column-reverse; */
}

.feed_card {
  background-color: #e6e6e6;
  padding: 15px;
  border: 2px solid black;
  border-radius: 12px;
  margin: 25px auto 25px auto;
}

.feed_card h3 {
  font-size: 28px;
  color: #E54B4B;
  text-transform: uppercase;
  padding: 10px;
}

.feed_link a {
  font-style: italic;
  color: #0170ac;
  font-size: 18px;
  text-decoration: underline;
}

.feed_link {
  text-align: left;
  padding: 10px;
}

.feed_card img {
  width: 95%;
  margin: 0px auto 0px auto;
  border: 1px solid #003049;
}

.feed_card_content {
  font-size: 18px;
  color: #003049;
  text-align: left;
  padding: 10px;
}

.post_date {
  font-style: italic;
  padding: 10px;
  color: #003049;
  font-size: 12px;
  text-align: left;
}

.page {
  background-color: #003049;
}

.page_title {
  font-size: 48px;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 45px auto 0px auto;
  font-family: exophis;
}

.page_content_container{
  width: 60%;
  background-color: #e6e6e6;
  border-radius: 12px;
  border: 2px solid black;
  box-shadow: 10px 10px 8px -8px rgba(0,0,0,0.62);
  -webkit-box-shadow: 10px 10px 8px -8px rgba(0,0,0,0.62);
  -moz-box-shadow: 10px 10px 8px -8px rgba(0,0,0,0.62);
  margin: 30px auto 0 auto;
  padding-bottom: 20px;
}

.page_content_header {
  font-family: exophis;
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 40px auto 20px auto;
  color: #E54B4B;
}

.page_content_subheader {
  font-family: exophis;
  font-weight: bold;
  width: fit-content;  
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  margin: 0px auto 40px auto;
  color: #E54B4B;
}

.header_img {
  width: 150px;
  margin: 20px auto -30px auto;
}

.flourish {
  width: 400px;
  margin: -10px auto 20px auto;
}

.rule_flourish {
  display: flex;
  width: 75px;
  margin: 0 auto 20px auto;
}

.page_content_text {
  width: 90%;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 150%;
  color: #003049;
  text-align: left;
}

.page_content_text a {
  color: #0170ac;
  text-decoration: none;
}

.page_content_text a :visited {
  color: #0170ac;
  text-decoration: none;
}

.address-text-title {
  color: #E54B4B;
  font-family: exophis;
  font-size: 28px;
  text-transform: uppercase;
  margin: 35px auto 10px auto;
}

.address-text-subtitle {
  color: #003049;
  font-family: exophis;
  font-size: 22px;
  text-transform: uppercase;
  margin: 25px auto 20px auto;
}

.address-text {
  color: #003049;
  font-family: exophis;
  font-size: 22px;
  font-weight: 900;
}

.gmaps-link {
  width: 50%;
}

.map {
  width: 500px;
  border-radius: 16px;
}

.address-text-container {
  margin: 15px auto 10px auto;
}

.link-logo {
  width: 50px;
  margin: 5px auto 0px auto;
}

.link-prompt {
  color: #003049;
  font-size: 12px;
  font-style: italic;
  margin: 0px auto 50px auto;
}

.question {
  color: #003049;
  font-family: lora;
  font-size: 24px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 900;
  margin: 0;
}

.app-link {
  display: inline;
  color: #E54B4B;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
}

.staff_type {
  font-family: exophis;
  font-weight: bold;
  width: fit-content;  
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 25px auto 40px auto;
  color: #003049;
}

.board_member_container {
  width: 85%;
  margin: 20px auto 40px auto;
  border: 1px solid #003049;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 12px;
  background-color: rgb(231, 228, 228);
}

.board_member {
  width: 90%;
  color: #E54B4B;
  font-size: 22px;
  text-align: left;
  font-family: exophis;
  font-weight: bold;
}

.profile-img {
  width: 200px;
  border: 1px solid #003049;
  margin: 20px auto 20px auto;
  border-radius: 12px;
}

.donation_type_container {
  margin-bottom: 40px;
  margin-top: 40px;
  width: 66%;
  text-align: left;
  border: 1px solid black;
  padding: 30px;
  border-radius: 12px;
  background-color: #f9f9f9;
}

.donation_type {
  width: 90%;
  color: #E54B4B;
  font-size: 28px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: exophis;
  font-weight: bold;
}

#paypal {
  width: 375px;
  padding-left: 5%;
}

#wishlist {
  width: 350px;
  padding-left: 5%;
  padding-bottom: 15px;
}

#connect {
  text-align: center;
  color: #003049;
  font-family: exophis;
  margin: 40px auto 10px auto;
}

.contact_bar {
  width: 98%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin: 30px auto 0 auto;
  background-color: #003049;
  border-radius: 12px;
  padding-top: 15px;
}

.contact_type {
  width: 100%;
}

.contact_type_detail {
  font-size: 14px;
  text-align: center;
  color: #FEF9E6;
  padding: 10px;
  width: 100%;
}

.contact_type .board_member {
  font-size: 22px;
  text-align: center;
}

.links_container{
  margin: 10px;
}

.link_box{
  margin-bottom: 40px;
}

.link_title {
  width: 90%;
  color: #003049;
  font-size: 32px;
  font-family: exophis;
  text-transform: uppercase;
}

.link_subheader {
  font-family: exophis;
  font-size: 24px;
  margin-bottom: 60px;
  margin-top: -10px;
  color: #003049;
}

.link_splash {
  width: 66%;
  margin: 20px auto 30px auto;
  border-radius: 12px;
  border: 2px solid black;
  box-shadow: 10px 10px 11px -8px rgba(0,0,0,0.52);
  -webkit-box-shadow: 10px 10px 11px -8px rgba(0,0,0,0.52);
  -moz-box-shadow: 10px 10px 11px -8px rgba(0,0,0,0.52);
}

.link_splash:hover {
  filter: brightness(70%);
}

.HomeTop {
  margin: 20px auto -20px auto;
}

.home-top-links {
  padding: 20px;
  color: #E54B4B;
  text-decoration: underline;
  text-transform: uppercase;
}

.footer {
  height:50px;
  width: 100%;  
  text-align:center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  left: 0;
  border-top: 4px double rgba(254,249,230,.25);
  margin-top: 75px;
  padding-bottom: 10px;
  font-size: 12px;
  color: #FEF9E6;
}

.footer_link {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #adadad;
}

.login_button {
  width: 150px;
  height: 45px;
  border-radius: 8px;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.post_form {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 20px auto 50px auto;
}

.post_form label {
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.post_form input {
  width: 50%;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 5px;
}

.post_form textarea {
  width: 50%;
  height: 200px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 15px;
  text-align: left;
}

.post_form button {
  margin: 20px auto;
  text-transform: uppercase;
  padding: 10px;
}

#update {
  font-size: 18px;
  color: #0170ac;
  font-weight: 600;
}

#delete {
  margin: 15px;
  padding: 10px;
  background-color: #dc1b1b;
  color: #e6e6e6;
  font-weight: 600;
  border-radius: 6px;
}

.post_img {
  width: 50%;
}


