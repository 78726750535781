@media only screen and (max-width: 580px){
  .header {
    background-image: url('./elements/header_banner2.png');
    background-color: #003049;
    background-size: cover;
  }
  
  .marquee-container {
    width: 100%;
    background-color: rgb(254, 249, 230, .83);
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .header_logo {
    width: 50%;
  }
  
  .logo_container {
    width: 100%;
    margin: 8px auto 8px auto;
  }
  
  .marquee {
    font-size: 48px;
    margin-top: -10px;
    }
  
  .marquee2 {
    font-size: 14px;
    color: #003049;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .accent {
    width: 50px;
    height: 50px;
    margin: auto;
  }

  .nav_bar {
    width: auto;
    display: flex;
    flex-direction: row;
    margin: 8px auto 8px auto;
    padding-top: 10px;
  }
  
  .nav_tiles {
    width: 75%;
    margin: 5px;
  }
  
  .welcome_message_container {
    margin: 15px auto 20px auto;
    width: 95%;
  }
  
  .welcome_message_container p {
    font-size: 18px;
    padding: 5px;
  }
  
  .welcome_message_container img {
    width: 95%;
    padding: 3.5px;
    margin: 12px auto;
  }
  
  .feed_container {
    width: 95%;
  }
  
  .feed_card {
    background-color: #e6e6e6;
    padding: 8px;
    margin: 12px auto 12px auto;
  }
  
  .feed_card h3 {
    font-size: 22px;
    padding: 5px;
  }
  
  .feed_link a {
    font-style: italic;
    color: #0170ac;
    font-size: 18px;
    text-decoration: underline;
  }
  
  .feed_link {
    padding: 5px;
  }
  
  .feed_card img {
  }
  
  .feed_card_content {
    font-size: 14px;
    padding: 5px;
  }
  
  .post_date {
    padding: 5px;
    font-size: 10px;
  }
  
  .page {
    background-color: #003049;
  }
  
  .page_title {
    font-size: 36px;
    margin: 23px auto 0px auto;
  }
  
  .page_content_container{
    width: 95%;
    margin: 15px auto 0 auto;
    padding-bottom: 10px;
  }
  
  .page_content_header {
    font-size: 28px;
    margin: 40px auto 20px auto;
  }
  
  .page_content_subheader {  
    font-size: 18px;
    margin: 0px auto 20px auto;
  }
  
  .header_img {
    width: 100px;
    margin: 10px auto -15px auto;
  }
  
  .flourish {
    width: 200px;
    margin: -5px auto 10px auto;
  }
  
  .rule_flourish {
    width: 60px;
    margin: 0 auto 10px auto;
  }
  
  .page_content_text {
    width: 95%;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .page_content_text a {
  }
  
  .page_content_text a :visited {
  }
  
  .address-text-title {
    font-size: 18px;
    margin: 13px auto 5px auto;
  }
  
  .address-text-subtitle {
    font-size: 18px;
    margin: 13px auto 10px auto;
  }
  
  .address-text {
    font-size: 18px;
  }
  
  .gmaps-link {
  }
  
  .map {
  }
  
  .address-text-container {
    margin: 7px auto 5px auto;
  }
  
  .link-logo {
    width: 50px;
    margin: 5px auto 0px auto;
  }

  *****
  
  .link-prompt {
    margin: 0px auto 25px auto;
  }
  
  .question {
    font-size: 18px;
  }
  
  .app-link {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  
  .staff_type { 
    font-size: 24px;
    margin: 13px auto 20px auto;
  }
  
  .board_member_container {
    width: 95%;
    margin: 10px auto 20px auto;
    padding-top: 7.5px;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  
  .board_member {
    width: 90%;
    font-size: 18px;
  }
  
  .profile-img {
    width: 150px;
    margin: 5px auto 5px 5%;
  }
  
  .donation_type_container {
    margin-bottom: 20px;
    margin-top: 20px;
    width: 90%;
    padding: 15px;
  }
  
  .donation_type {
    width: 90%;
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  #paypal {
    width: 275px;
    padding-left: 2.5%;
  }
  
  #wishlist {
    width: 275px;
    padding-left: .25%;
    padding-bottom: 7.5px;
  }
  
  #connect {
    margin: 20px auto 5px auto;
  }
  
  .contact_bar {
    display: flex;
    flex-direction: column;
    width: 98%;
    margin: 15px auto 0 auto;
    padding-top: 7.5px;
  }
  
  .contact_type {
    width: 100%;
  }
  
  .contact_type_detail {
    font-size: 14px;
    text-align: center;
    color: #FEF9E6;
    padding: 5px;
    width: 100%;
  }
  
  .contact_type .board_member {
    font-size: 18px;
    text-align: center;
  }
  
  .links_container{
    margin: 5px;
  }
  
  .link_box{
    margin-bottom: 10px;
  }
  
  .link_title {
    width: 100%;
    font-size: 24px;
  }
  
  .link_subheader {
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: -10px;
  }
  
  .link_splash {
    width: 95%;
    margin: 10px auto 15px auto;
  }
  
  .link_splash:hover {
  }
  
  .HomeTop {
    display: flex;
    flex-direction: row;
  }
  
  .home-top-links {
    height: max-content;
  }
  
  .footer {
    height: 50px; 
    margin-top: 37.5px;
    padding-bottom: 10px;
    font-size: 12px;
  }
  
  .footer_link {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
  .login_button {
  }
  
  .post_form {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 20px auto 50px auto;
  }
  
  .post_form label {
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  
  .post_form input {
    width: 50%;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 5px;
  }
  
  .post_form textarea {
    width: 50%;
    height: 200px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 15px;
    text-align: left;
  }
  
  .post_form button {
    margin: 20px auto;
    text-transform: uppercase;
    padding: 10px;
  }
  
  #update {
    font-size: 12px;
  }
  
  #delete {
    margin: 7.5px;
    padding: 5px;
  }
  
  .post_img {
    width: 75%;
  }
  
  
  

}