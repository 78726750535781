#contact-form {
  background-color: #003049;
  font-family: 'Antic Slab', serif;
  text-transform: uppercase;
  font-size: 24px;
  text-align: left;
  padding: 10px 20px 30px 20px;
  width:50%;
  border-radius:12px;
  -webkit-border-radius:12px;
  -moz-border-radius:12px;
  margin-bottom: 30px;
  margin-top: 30px;
  box-shadow: 10px 10px 11px -8px rgba(0,0,0,0.52);
  -webkit-box-shadow: 10px 10px 11px -8px rgba(0,0,0,0.52);
  -moz-box-shadow: 10px 10px 11px -8px rgba(0,0,0,0.52);
}

#contact-form input,   
#contact-form select,   
#contact-form textarea,   
#contact-form label { 
  font-size: 15px;  
  margin-bottom: 2px;
  font-family: 'Antic Slab', serif;
} 
#contact-form input,   
#contact-form select,   
#contact-form textarea { 
  width:100%;
  background: rgb(236, 235, 235);
  border: 0; 
  -moz-border-radius: 4px;  
  -webkit-border-radius: 4px;  
  border-radius: 4px;
  margin-bottom: 25px;  
  padding: 5px;  
}  

#contact-form textarea {
  height: 150px;
}
#contact-form button[type="submit"] {
  cursor:pointer;
  width:25%;
  border:none;
  background: #E54B4B;
  color:#003049;
  margin:0 0 5px;
  padding:10px;
  border-radius:5px;
}
#contact-form button[type="submit"]:hover {
  background-color: #6EA070;
  -moz-transition:background 0.3s ease-in-out;
  transition:background-color 0.3s ease-in-out;
}

input:required, textarea:required {  
  box-shadow: none;
  -moz-box-shadow: none;  
  -webkit-box-shadow: none;  
  -o-box-shadow: none;  
}

@media only screen and (max-width: 580px) {
  #contact-form{
      font-size: 24px;
      padding: 5px 5px 20px 10px;
      width:95%;
      margin: 10px auto 10px auto
  }

  #contact-form input,  
  #contact-form textarea { 
  width:95%;
} 
  
  #contact-form select {
    width: 100%;

}
  #contact-form button[type="submit"] {
    width:95%;
  }
}

.opt-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.availability {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.days {
  border: 1px solid white;
  border-radius: 12px;
  width: 40%;
  padding: 20px;
  margin: 10px auto;
}

.y-n-container {
  display: flex;
  flex-direction: row;
  width: 50%;
}